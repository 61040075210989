import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css'; // Подключаем CSS файл
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome для кнопок

const MediaControlBar = ({ isPlaying, onPlayPause, onSeekChange, currentTime, duration, onToggleFullscreen }) => {
    const [showControls, setShowControls] = useState(true);

    useEffect(() => {
        let timer;
        if (showControls) {
            timer = setTimeout(() => setShowControls(false), 2000);
        }

        const handleMouseMove = () => {
            setShowControls(true);
            clearTimeout(timer);
            timer = setTimeout(() => setShowControls(false), 2000);
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [showControls]);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className={`media-control-bar ${showControls ? 'show' : 'hide'}`}>
            <div className='flex'>
                <input
                    type="range"
                    min="0"
                    max={duration || 100}
                    value={currentTime}
                    onChange={onSeekChange}
                    className="seek-bar"
                />
            </div>
            <div className="flex content-center justify-between">
                <button onClick={onPlayPause} className="play-pause-btn">
                    <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                </button>
                <div className="time-display">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>
                <button onClick={onToggleFullscreen} className="fullscreen-btn">
                    <i className='fa fa-expand'></i>
                </button>
            </div>
        </div>
    );
};

const VideoPlayer = () => {
    const videoUrls = [
        "https://vz-fac1cfaa-788.b-cdn.net/e7d83f63-4322-49a9-9339-58355a5875a8/playlist.m3u8",
        "https://vz-fac1cfaa-788.b-cdn.net/375b4555-48ae-4ac7-bb29-df19ac39d7d6/playlist.m3u8",
        "https://vz-fac1cfaa-788.b-cdn.net/3facdd97-0cb5-40d4-9903-f9527d955dee/playlist.m3u8",
        "https://vz-fac1cfaa-788.b-cdn.net/c75645e6-3527-485c-b5b9-82a48c94baad/playlist.m3u8"
    ];

    const playerRefs = useRef([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [expandedPlayer, setExpandedPlayer] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [loadStatus, setLoadStatus] = useState(Array(videoUrls.length).fill('loading'));
    const [isBuffering, setIsBuffering] = useState(false); // новое состояние

    useEffect(() => {
        const interval = setInterval(() => {
            if (playerRefs.current[0]) {
                setCurrentTime(playerRefs.current[0].getCurrentTime());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPlaying]);

    const playAllVideos = () => {
        playerRefs.current.forEach((player) => player.seekTo(currentTime));
        setIsPlaying(true);
    };

    const pauseAllVideos = () => {
        setIsPlaying(false);
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            pauseAllVideos();
        } else {
            playAllVideos();
        }
    };

    const handleSeekChange = (event) => {
        const newTime = parseFloat(event.target.value);
        setCurrentTime(newTime);
        playerRefs.current.forEach((player) => player.seekTo(newTime));
    };

    const handleVideoClick = (index) => {
        if (expandedPlayer === index) {
            setExpandedPlayer(null);
        } else {
            setExpandedPlayer(index);
        }
    };

    const resetView = () => {
        setExpandedPlayer(null);
        setIsFullscreen(false);
    };

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => console.log(err));
            setIsFullscreen(true);
        } else {
            document.exitFullscreen();
            setIsFullscreen(false);
        }
    };

    const handleStartViewing = () => {
        setShowIntro(false);
    };

    const handleReady = (index) => {
        const updatedStatus = [...loadStatus];
        updatedStatus[index] = 'loaded';
        setLoadStatus(updatedStatus);
    };

    const handleError = (index) => {
        const updatedStatus = [...loadStatus];
        updatedStatus[index] = 'error';
        setLoadStatus(updatedStatus);
    };

    const handleBuffer = () => {
        setIsBuffering(true);
    };

    const handleBufferEnd = () => {
        setIsBuffering(false);
    };

    return (
        <div className="main-container">
            {showIntro && (
                <div className="modal" onClick={handleStartViewing}>
                    <div className="modal-content">
                        <h2 className='text-3xl uppercase pb-3'>Как <il>пользоваться</il> плеером:</h2>
                        <hr className='new1 pb-3' />
                            <p className='uppercase'>Запуск видео:</p>
                                <ol className='list-inside list-decimal lowercase pb-3'>
                                    <li>Дождитесь загрузки всех 4-х видео, если они не загрузились, обновите страницу и начните заново.</li>
                                    <li>Нажмите кнопку <i className='fa fa-play'></i> внизу, чтобы одновременно запустить все четыре видео.</li>
                                </ol>
                                <hr className='new1 pb-3' />
                            <p className='uppercase'>Полноэкранный режим:   </p>
                                <ol className='list-inside list-none lowercase pb-3'>
                                    <li>Чтобы развернуть плеер на весь экран, нажмите на иконку <i className='fa fa-expand'></i> в правом нижнем углу.</li>
                                </ol>
                                <hr className='new1 pb-3' />
                            <p className='uppercase'>Выбор камеры:      </p>
                                <ol className='list-inside list-decimal lowercase pb-3'>
                                    <li>Если хотите увеличить одно из видео на весь экран, просто кликните по нему.</li>
                                    <li>Чтобы вернуться к просмотру всех четырёх ракурсов, снова нажмите на видео.</li>
                                </ol>
                                <hr className='new1 pb-3' />
                        <p className='uppercase'>Пауза и продолжение:    </p>
                                <ol className='list-inside list-none lowercase pb-3'>
                                    <li>Нажмите <i className='fa fa-play'></i> или <i className='fa fa-pause'></i> в левом нижнем углу, чтобы остановить или продолжить воспроизведение.</li>
                                </ol>
                                <hr className='new1 pb-3' />
                        <p className='uppercase'>Обратите внимание:</p>
                        <ol className='list-inside list-none lowercase pb-3'>
                            <li>При переходе в полноэкранный режим может отобразиться только один ракурс. Если это случилось, просто нажмите на кнопку «вернуться» в верхней части экрана, чтобы вернуться к просмотру всех четырёх видео.</li>
                        </ol>
                        <hr className='new1 pb-3' />
                        <button onClick={handleStartViewing} className="start-viewing-btn">
                            Начать просмотр
                        </button>
                  
                    </div>
                </div>
            )}
            <div className={`video-player-container ${showIntro ? 'blurred' : ''}`}>
                {(expandedPlayer !== null || isFullscreen) && (
                    <button onClick={resetView} className="reset-view-btn">
                        ВЕРНУТЬСЯ
                    </button>
                )}
                {videoUrls.map((url, index) => (
                    <div
                        key={index}
                        className={`video-wrapper ${expandedPlayer === index ? 'expanded' : ''} ${isFullscreen ? 'fullscreen-video' : ''}`}
                        onClick={() => handleVideoClick(index)}
                    >
                        {loadStatus[index] === 'error' ? (
                            <div className="error-message">Ошибка загрузки видео. Пожалуйста, попробуйте позже.</div>
                        ) : (
                            <ReactPlayer
                                ref={(el) => (playerRefs.current[index] = el)}
                                url={url}
                                playing={isPlaying}
                                controls={false}
                                muted={index !== 1} // Звук только у второго видео
                                width="100%"
                                height="100%"
                                onReady={() => handleReady(index)}
                                onError={() => handleError(index)}
                                onDuration={(d) => setDuration(d)}
                                onBuffer={handleBuffer} // добавлено
                                onBufferEnd={handleBufferEnd} // добавлено
                                playsinline
                            />
                        )}
                    </div>
                ))}
                {isBuffering && <div className="loading-message">Загрузка видео...</div>} {/* сообщение о загрузке */}
                <MediaControlBar
                    isPlaying={isPlaying}
                    onPlayPause={handlePlayPause}
                    onSeekChange={handleSeekChange}
                    duration={duration}
                    currentTime={currentTime}
                    onToggleFullscreen={toggleFullscreen}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;
